import React, {useState} from 'react';
import { Link, Redirect } from 'react-router-dom';

export default function Home(props) {
    let rollImage = require("../pages/images/letsroll.png")
    const [name,setName] = useState("Dean")
    const [showError,setShowError] = useState(false)
    const [redirect,setRedirect] = useState(false)
    const checkOrRejectPath = ()=>{
        console.log("check or Reject")
        try {
            require("../users/"+name+".json");
            setShowError(false)
            setRedirect(true)
        } catch (err) {
            setShowError(true)
        }
    }
    return (
        <>
            {//if nameis correct redirect
                redirect?<Redirect to={"/user/"+name} />:<></>
            }
            <h1 className ="theme-h1-user-page">Welcome to Nat 1</h1>
            <p className="theme-p-centered">where all your dreams never come true...</p>
            {//if name is incorrect show error
                showError?
                <p className="theme-p-centered" style={{color:"red"}}>ERROR name is incorrect</p>
                :<></>
            }
            <div className="form-holder">
				<input id="uname" className="login-input" value={name} onChange={(e)=>{setName(e.target.value)}}/>
			</div>

            <div className="button-container" onClick={(e)=>{checkOrRejectPath()}}>
                <img className="button-roll" src={rollImage}></img>
            </div>
        </>

    )
}