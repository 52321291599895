import React from 'react';
import { Link } from 'react-router-dom';

function getLevelSpells (Level,Spells) {
    var filteredSpells =  Spells.filter(function(Spells) {
        return Spells.Level == Level;
    });

    return(filteredSpells)
}

function getHighestLevelSpell(Spellslots) {

    let highestlevel = 0
    let intCurrentSpellslot = 0
    let currentSpellslot = ""
    for(const [key, value] of Object.entries(Spellslots)){
        currentSpellslot = `${key}`
        intCurrentSpellslot = parseInt(currentSpellslot, 10)
        if (intCurrentSpellslot > highestlevel){
            highestlevel = intCurrentSpellslot
        }

    }
    return(
        highestlevel
    )
}


export default function getAllSpells (props){
    let playername = props.details.playerName
    let SpellLevel = props.details.SpellLevel

    const player = require("../Characters/"+playername+".json")
    let HighestLevelSpell = getHighestLevelSpell(player.SpellSlots)
    let currentLevel = SpellLevel
    let currentSpells = []
    let tempSpellSlots = ""
       
        
    if(currentLevel <= HighestLevelSpell){
        currentSpells = getLevelSpells(currentLevel,player.Spells)
        tempSpellSlots += player.SpellSlots[currentLevel]
        return(
            <>
            <h3 className="theme-h3">Level {currentLevel}: <input className = "spell-input" type="text" maxlength="2" size="1"></input> /{tempSpellSlots}</h3>
            {
                currentSpells.map((spell,i)=>{
                    return(
                        <>
                        <h4 className ="theme-h4">{spell.Name}</h4>
                        <tr>
                        <th className="theme-th">Level</th>
                        <th className="theme-th">Casting Time</th>
                        <th className="theme-th">Range/Area</th>
                        <th className="theme-th">Components</th>
                        <th className="theme-th">Duration</th>
                        <th className="theme-th">School</th>
                        <th className="theme-th">Attack/Save</th>
                        <th className="theme-th">Damage/Effect</th>
                        </tr>
                        <tr>
                        <td className="theme-td">{spell.Level}</td>
                        <td className="theme-td">{spell.CastingTime}</td>
                        <td className="theme-td">{spell.RangeArea}</td>
                        <td className="theme-td">{spell.Components}</td>
                        <td className="theme-td">{spell.Duration}</td>
                        <td className="theme-td">{spell.School}</td>
                        <td className="theme-td">{spell.AttackSave}</td>
                        <td className="theme-td">{spell.DamageEffect}</td>
                        </tr>
                        <p>
                            {spell.Details}
                        </p>
                        </>
    
                    )
                })
            }
            </>
        )
    }

}
