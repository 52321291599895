import React, {useState, useEffect} from 'react';
import ComponentImage from '../components/characterimage';
import DMImage from '../components/dmimage';


function buildCharacterShortStat(characters) {
    let returnList = []
    for (let characterName of characters) {
        let characterSheet = require("../Characters/" + characterName + ".json")
        returnList.push(
            {
                "Name": characterSheet.CharacterName,
                "Level": characterSheet.Level,
                "Class": characterSheet.Class,
                "HP": characterSheet.HitPoints,
                "Image": characterSheet.Image,
                "Stats": characterSheet.Stats
            }
        )
    }

    return (returnList)

}

export default function UserSheet(props) {
    const Config = require("../config.json")
    const [showUser,setshowUser] = useState(false)
    //email = value setEmail = state changer useState = default value
    const [user,setUser] = useState(false)
    const [allCharacterStats,setallCharacterStats] = useState([])
    const [allDMCharacters,setallDMCharacters] = useState([])




    useEffect(async ()=>{
        let userName = props.match.params.username
        const data = { username: userName};
        const response = await fetch(Config.url.getUser,{
            method: 'POST',
            crossDomain: true,
            headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data)
        });
        const myJson = await response.json()
        console.log(myJson)
        let returnData = JSON.parse(myJson.body)
        console.log(returnData.UserName)
        let user = returnData
        console.log("user object")
        console.log(user)

        


        setUser(user) //react set state function
        if(user !== ''){
            setshowUser(true)
            

            let characters = user.Characters
            let allplayercharcters = []
            let allTempDMCharacters = []
        
            for (let index in characters) {
                
                let characterName = characters[index].Name
                let isDungeonMaster = characters[index].DungeonMaster
        
                if (isDungeonMaster == 'true'){
                    allTempDMCharacters.push(characterName)
                }
                else{
                    allplayercharcters.push(characterName)
                }
                
            }
        
    
            setallCharacterStats(buildCharacterShortStat(allplayercharcters))
            setallDMCharacters(allTempDMCharacters)


            console.log("all dm char" + allDMCharacters)
        
        }
        else{
            setshowUser(false)
        }
    },[]) //figure out conditional run this will help with multiple components

    console.log ("posting now")      
    

    return (
        <>
            {//if nameis correct redirect
                user.UserName !== ''? //ternery statement
                <>
                <h1 className="theme-h1-user-page">{user.UserName}</h1>
                
    
                {
                    console.log('allo')}{
                    console.log(allCharacterStats)}{

                    allCharacterStats.map((char, i) => {
                        let tempData = { "Image": char.Image, "characterName": char.Name }
    
                        return (
                            <div className='char-container' key={"char-"+i}>
                                <ComponentImage details={tempData} />
                                <h2 className="theme-h2-user-page">{char.Name} - {char.Level} {char.Class}</h2>
                                <table className="char-stat-table">
                                    <thead>
                                        <tr>
                                            <th className="theme-th">HP</th>
                                            <th className="theme-th">Armor Class</th>
                                            <th className="theme-th">Speed</th>
                                            <th className="theme-th">Initiative</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="theme-td">{char.HP}</td>
                                            <td className="theme-td">{char.Stats.ArmorClass}</td>
                                            <td className="theme-td">{char.Stats.Speed}</td>
                                            <td className="theme-td">{char.Stats.Initiative}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )
                    })
                }
    
                {
                 allDMCharacters.map((dm, i) =>{
                     console.log("building thing:-: " + dm)
                        let tempData = { "Image": 'none.png', "characterName": dm}
                        return(
                            <div className='char-container' key={"char-"+i}>
                            <DMImage details={tempData} />
                            <h2 className="theme-h2-user-dm-page">Dungeon Master - {dm}</h2>
                            </div>
                        )
                    
                 })
                }
            </>
                :<p>
                    test with no email
                </p>
            }

            

        </>
    )

}