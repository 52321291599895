import React from 'react';
import logo from './logo.svg';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Playersheet from './pages/Playersheet';
import Usersheet from './pages/Usersheet';
import DMSheet from './pages/DMSheet';
import Home from './pages/Home';
import Testrest from './pages/testrest';

function App() {
  return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/:playerName" component={Playersheet} />
          <Route exact path="/user/:username" component={Usersheet} />
          <Route exact path="/campaign/:campaign" component={DMSheet} />
          <Route exact path="/testrest/test/:campaign" component={Testrest} />
        </Switch>
      </Router>
  );
}

export default App;
