import React from 'react';
import { Link } from 'react-router-dom';
import ComponentSpells from '../components/spells';


function getKeyValueList (Stats) {
    let returnObject = {}
    let keys = []
    let values = []
    for(const [key, value] of Object.entries(Stats)){
        keys.push(`${key}`)
        values.push(`${value}`)
    }
    returnObject.keys =  keys
    returnObject.values = values
    
    return(
        returnObject
    )
}


function getHighestLevelSpell(Spellslots) {

    let highestlevel = 0
    let intCurrentSpellslot = 0
    let currentSpellslot = ""
    for(const [key, value] of Object.entries(Spellslots)){
        currentSpellslot = `${key}`
        intCurrentSpellslot = parseInt(currentSpellslot, 10)
        if (intCurrentSpellslot > highestlevel){
            highestlevel = intCurrentSpellslot
        }

    }
    return(
        highestlevel
    )
}

function getLevelSpells (Level,Spells) {
    var filteredSpells =  Spells.filter(function(Spells) {
        return Spells.Level == Level;
    });

    return(filteredSpells)
}





export default function Playersheet(props) {
    let playername = props.match.params.playerName
    const player = require("../Characters/"+playername+".json")
    let playerStats = getKeyValueList(player.Stats)
    let spellSlots = getKeyValueList(player.SpellSlots)
    let HighestLevelSpell = getHighestLevelSpell(player.SpellSlots)
    let allCantrips = getLevelSpells("Cantrip",player.Spells)
   // let allSpells = getAllSpells(player.Spells,player.SpellSlots)
   let SpellLevelList = []

   for (let index = 1; index <= HighestLevelSpell; index++) {
       SpellLevelList.push(index)
   }

   //get the image
    const imageFileName = player.Image
    let characterImage = require("./images/characters/"+player.Image)
   

    return (
        <body> 
 
        <img className="character-img" src={characterImage}></img>

        <h1 className="theme-h1">{player.CharacterName} - level {player.Level} {player.Class}</h1>
        <h2 className="theme-h2-centered">HP: <input className="hp-input" type="text" maxlength="3" size="1"></input> /{player.HitPoints}</h2>
        <table className = "char-stat-table">
        <tr>
            {
                playerStats.keys.map((title,i)=>{
                    return(<th className="theme-th">{title}</th>)
                })
            }

        </tr>
        <tr>
            {
                playerStats.values.map((title,i)=>{
                    return(<td className="theme-td">{title}</td>)
                })
            }

        </tr>
        </table>

        
        {
            allCantrips.map((spell,i)=>{
                return(
                    <>
                    <h4 className="theme-h4">{spell.Name}</h4>
                    <tr>
                    <th className="theme-th">Level</th>
                    <th className="theme-th">Casting Time</th>
                    <th className="theme-th">Range/Area</th>
                    <th className="theme-th">Components</th>
                    <th className="theme-th">Duration</th>
                    <th className="theme-th">School</th>
                    <th className="theme-th">Attack/Save</th>
                    <th className="theme-th">Damage/Effect</th>
                    </tr>
                    <tr>
                    <td className="theme-td">{spell.Level}</td>
                    <td className="theme-td">{spell.CastingTime}</td>
                    <td className="theme-td">{spell.RangeArea}</td>
                    <td className="theme-td">{spell.Components}</td>
                    <td className="theme-td">{spell.Duration}</td>
                    <td className="theme-td">{spell.School}</td>
                    <td className="theme-td">{spell.AttackSave}</td>
                    <td className="theme-td">{spell.DamageEffect}</td>
                    </tr>
                    <p>
                        {spell.Details}
                    </p>
                    </>

                )
            })

        }

        {
            
            SpellLevelList.map((level,i)=>{
                let tempData = {"playerName": playername,"SpellLevel": level}
                return(<ComponentSpells details={tempData}/>)   
                })
        
        }



        </body>
    )    

}